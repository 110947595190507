/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased;
  }
}

/* Custom animations can go here */
@layer utilities {
  .animate-fade-in {
    animation: fadeIn 0.5s ease-in;
  }

  .animate-slide-up {
    animation: slideUp 0.5s ease-out;
  }

  .animate-slide-right {
    animation: slideRight 0.5s ease-out;
  }

  .animate-slide-left {
    animation: slideLeft 0.5s ease-out;
  }

  .animate-scale-up {
    animation: scaleUp 0.3s ease-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Swiper styles */
.swiper-button-next,
.swiper-button-prev {
  color: white !important;
  background: rgba(0, 0, 0, 0.3);
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
}

.swiper-pagination-bullet {
  background: white !important;
  opacity: 0.7 !important;
  transition: all 0.3s ease !important;
}

.swiper-pagination-bullet-active {
  background: white !important;
  opacity: 1 !important;
  transform: scale(1.2);
}

/* Accent colors */
:root {
  --color-accent: #3b82f6;
  --color-accent-dark: #2563eb;
  --color-primary: #1e40af;
  --color-secondary: #6b7280;
  --color-accent-light: #60a5fa;
  --color-accent-darker: #1d4ed8;
}

.bg-accent {
  background-color: var(--color-accent);
}

.hover\:bg-accent-dark:hover {
  background-color: var(--color-accent-dark);
}

/* Button animations */
.btn-animate {
  transition: all 0.5s ease;
  background-size: 200% auto;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.btn-animate:hover {
  background-position: right center;
  box-shadow: 0 6px 20px rgba(0,0,0,0.15);
}

.btn-primary {
  background-image: linear-gradient(
    45deg, 
    var(--color-accent-dark) 0%, 
    var(--color-accent) 50%,
    var(--color-accent-dark) 100%
  );
}

.btn-secondary {
  background-image: linear-gradient(
    45deg,
    rgb(255, 255, 255) 0%,
    rgb(249, 250, 251) 50%,
    rgb(255, 255, 255) 100%
  );
}

/* Focus states */
.btn-primary:focus,
.btn-secondary:focus,
.service-card:focus-within {
  outline: 2px solid var(--color-accent);
  outline-offset: 2px;
  ring: 2px var(--color-accent);
}

/* Active states */
.btn-primary:active,
.btn-secondary:active {
  transform: translateY(2px);
}

/* Card image hover zoom */
.service-card-image {
  transform-origin: center;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.service-card:hover .service-card-image {
  transform: scale(1.1);
}

/* Enhanced loading states */
.loading-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

/* Enhanced card and button effects */
.service-card {
  position: relative;
  isolation: isolate;
  transition: all 0.2s ease-out;
}

.service-card::after {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at center,
    rgba(59, 130, 246, 0.1),
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.service-card:hover::after {
  opacity: 1;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Team member card effects */
.team-member {
  position: relative;
  isolation: isolate;
}

.team-member::before {
  content: '';
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle at var(--mouse-x, center) var(--mouse-y, center),
    rgba(255, 255, 255, 0.1) 0%,
    transparent 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
}

.team-member:hover::before {
  opacity: 1;
}

/* Team member hover animations */
.team-member-content {
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-member:hover .team-member-content {
  transform: translateY(0);
}

/* Team member image zoom */
.team-member-image {
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.team-member:hover .team-member-image {
  transform: scale(1.1);
}

/* Enhanced button and background transitions */
.gradient-hover {
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.3s ease-out;
}

.gradient-hover:hover {
  background-position: left bottom;
}

/* Button hover states */
.hover-gradient {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hover-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, var(--color-accent-dark), var(--color-accent));
  z-index: -1;
  transition: opacity 0.3s ease-out;
  opacity: 0;
}

.hover-gradient:hover::before {
  opacity: 1;
}

/* Prevent white-on-white text */
.btn-light {
  color: var(--color-accent);
  background: white;
  border: 2px solid var(--color-accent);
}

.btn-light:hover {
  color: white;
  background: var(--color-accent);
  border-color: transparent;
}
